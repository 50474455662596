<template>
  <!-- Add tooltip -->
  <div v-if="item.serviceStatus" class="m-1">
    <v-chip
      class="service-machine-item-chip justify-center"
      :title="item.serviceStatus.name"
      small
      :color="color"
      @click="handleClick"
    >
      <span class="text-truncate">
        {{ item.name }}{{ singleLine ? " | " + item.serviceStatus.name : "" }}
      </span>
    </v-chip>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    item: Object,
    machineId: Number,
    singleLine: {
      type: Boolean,
      deafult: false
    }
  },
  computed: {
    color() {
      return this.item.serviceStatus.value === 0
        ? "success"
        : this.item.serviceStatus.value === 1
        ? "warning"
        : "error";
    }
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: "add-service-machine-rows",
        params: { machineId: this.machineId, serviceTypeId: this.item.id },
        query: this.$route.query
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.service-machine-item-chip
  font-size: 11px !important
</style>
