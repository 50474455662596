<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{
              $t("MENU.ITEM.SERVICE.MACHINE_SERVICES", {
                machine: machine ? machine.fullName : null
              })
            }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['service-create']"
            :to="{
              name: 'add-service-machine-rows',
              params: { machineId: machine ? machine.id : null },
              query: $route.query
            }"
            class="btn btn-light-success font-weight-bolder ml-2"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-if="!isPageLoading">
        <div
          v-if="machine.lastCharge"
          class="mx-8 mt-5 d-flex align-center flex-wrap"
        >
          <b class="mr-1">Текущи километри/моточасове:</b>
          <b class="primary--text">
            {{ machine.lastCharge.mileage }}
            {{ machine.lastCharge ? "км" : "ч." }}
          </b>
        </div>

        <div class="mx-8 mt-5 d-flex align-center flex-wrap">
          <b class="mr-1">Предстоящи обслужвания/такси:</b>
          <ServiceMachineUpcomingItem
            v-for="sp in machine.serviceTypes"
            :key="sp.id"
            :item="sp"
            :machineId="machine.id"
            singleLine
          />
        </div>

        <SearchTools :searchQuery.sync="searchQuery" hideActions>
          <template v-slot:prepend> </template>
        </SearchTools>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="tableLoading"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          :search="searchQuery"
          loading-text="Loading... Please wait"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | formatDate }}
          </template>

          <template v-slot:[`item.serviceExpenses`]="{ item }">
            {{ item.serviceExpenses.length > 0 ? "Да" : "Не" }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <router-link
                v-if="checkIfUserHasPermission(['service-update'])"
                :to="{
                  name: 'edit-service-machine-rows',
                  params: { machineId: item.machineId, id: item.id },
                  query: $route.query
                }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.EDIT") }}</span>
                </v-tooltip>
              </router-link>
            </div>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import { FETCH_MACHINE_FOR_SERVICE } from "@/modules/service-machines/store/service-machines.module";

import SearchTools from "@/core/components/table/SearchTools.vue";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import ServiceMachineUpcomingItem from "../components/ServiceMachineUpcomingItem.vue";

export default {
  mixins: [permissionMixin],
  components: {
    SearchTools,
    ServiceMachineUpcomingItem
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      searchQuery: "",
      gasStations: [],
      totalItems: 100,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30
      },
      filters: {},
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        { text: this.$i18n.t("FORM_LABELS.DATE"), value: "date" },
        {
          text: this.$i18n.t("FORM_LABELS.SERVICE_TYPE"),
          value: "serviceType.name"
        },
        {
          text: this.$i18n.t("FORM_LABELS.PERIOD_VALUE"),
          value: "mileage"
        },
        {
          text: this.$i18n.t("FORM_LABELS.DESCRIPTION"),
          value: "description"
        },
        {
          text: this.$i18n.t("FORM_LABELS.EXPENSES"),
          value: "serviceExpenses"
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.SERVICE.MACHINES"),
        route: { name: "list-service-machines", query: this.$route.query }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);
    this.getServiceMachine();
  },
  computed: {
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      return this.headers;
    }
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      if (this.options.page == 1) {
        this.getServiceMachine();
      } else {
        this.options.page = 1;
      }
    },
    doClear() {
      this.searchQuery = "";
      if (this.options.page == 1) {
        this.getServiceMachine();
      } else {
        this.options.page = 1;
      }
    },
    getServiceMachine() {
      let id = this.$route.params.id;
      this.id = id;
      this.$store
        .dispatch(FETCH_MACHINE_FOR_SERVICE, id)
        .then(data => {
          this.machine = data.data;
          this.items = data.data.serviceMachines;
          this.isPageLoading = false;
          this.tableLoading = false;
        })
        .catch(response => {
          if (response.status === 404) {
            this.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-service-machines" });
          }
        });
    }
  }
};
</script>
